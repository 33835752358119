<template>

  <!-- Table Container Card -->
  <b-card
    class="pb-0 mb-0"
    no-body
  >

    <div
      data-v-73e9dc24=""
      class="position-relative table-responsive"
    >
      <table
        id="__BVID__435"
        role="table"
        aria-busy="false"
        aria-colcount="4"
        class="table b-table"
      >
        <thead
          role="rowgroup"
          class=""
        >
          <tr
            role="row"
            class=""
          >
            <th
              role="columnheader"
              scope="col"
              aria-colindex="1"
              class=""
            >
              <div>Lp.</div>
            </th><th
              role="columnheader"
              scope="col"
              aria-colindex="2"
              class=""
            >
              <div>Wyszczególnienie</div>
            </th><th
              role="columnheader"
              scope="col"
              aria-colindex="3"
              class=""
            >
              <div>Stawka</div>
            </th><th
              role="columnheader"
              scope="col"
              aria-colindex="4"
              class=""
            >
              <div>Wartość</div>
            </th>
          </tr>
        </thead>

        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              I
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Wynagrodzenie brutto
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            /><td
              aria-colindex="4"
              role="cell"
              class=""
            >
              3 200,00
            </td>
          </tr>
        </tbody>

        <!-- Wynagrodzenie podstawowe  -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              1
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Wynagrodzenie podstawowe
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            /><td
              aria-colindex="4"
              role="cell"
              class=""
            >
              3 200,00
            </td>
          </tr>
        </tbody>

        <!-- Podstawa składki emerytalnej i rentowej  -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              II
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Podstawa składki emerytalnej i rentowej
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            /><td
              aria-colindex="4"
              role="cell"
              class=""
            >
              3 200,00
            </td>
          </tr>
        </tbody>

        <!-- składka na ubezpieczenie emerytalne   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              1
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              składka na ubezpieczenie emerytalne
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >
              9.76 %
            </td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              312,32
            </td>
          </tr>
        </tbody>

        <!-- składka na ubezpieczenie rentowe   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              2
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              składka na ubezpieczenie emerytalne
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >
              1,50%
            </td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              48,00
            </td>
          </tr>
        </tbody>

        <!-- Podstawa składki chorobowej  -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              III
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Podstawa składki chorobowej
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            /><td
              aria-colindex="4"
              role="cell"
              class=""
            >
              3 200,00
            </td>
          </tr>
        </tbody>

        <!-- składka na ubezpieczenie chorobowe   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              2
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              składka na ubezpieczenie chorobowe
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >
              2,45%
            </td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              78,40
            </td>
          </tr>
        </tbody>

        <!-- Podstawa składki zdrowotnej  -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              IV
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Podstawa składki zdrowotnej
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            /><td
              aria-colindex="4"
              role="cell"
              class=""
            >
              2 761,28
            </td>
          </tr>
        </tbody>

        <!-- składka zdrowotna   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              1
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              składka zdrowotna
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >
              7,75%
            </td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              214,00
            </td>
          </tr>
        </tbody>

        <!-- składka zdrowotna   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              2
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              składka zdrowotna
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >
              1,25%
            </td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              34,52
            </td>
          </tr>
        </tbody>

        <!-- składka zdrowotna do odprowadzenia   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              2
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              składka zdrowotna do odprowadzenia
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            />

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              248,52
            </td>
          </tr>
        </tbody>

        <!-- V DOCHÓD   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              V
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Dochód
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            /><td
              aria-colindex="4"
              role="cell"
              class=""
            >
              2 761,28
            </td>
          </tr>
        </tbody>

        <!-- VI KOSZTY UZYSKANIA PRZYCHODU   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              VI
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Koszty uzyskania przychodu
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            /><td
              aria-colindex="4"
              role="cell"
              class=""
            >
              111,25
            </td>
          </tr>
        </tbody>

        <!-- VII Podstawa opodatkowania   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              VII
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Podstawa opodatkowania
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            /><td
              aria-colindex="4"
              role="cell"
              class=""
            >
              2 650,00
            </td>
          </tr>
        </tbody>

        <!-- VIII Podatek   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              VIII
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Podatek
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >18%</td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              477,00
            </td>
          </tr>
        </tbody>

        <!-- IX Ulga podatkowa   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              IX
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Ulga podatkowa
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            />

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              46,33
            </td>
          </tr>
        </tbody>

        <!-- X Podatek wyliczony   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              X
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Podatek wyliczony
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            />

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              430,67
            </td>
          </tr>
        </tbody>

        <!-- XI Podatek do odprowadzenia do US(X –IV.1)  -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              X
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Podatek do odprowadzenia do US(X –IV.1)
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            />

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              217,00
            </td>
          </tr>
        </tbody>

        <!-- XII Wynagrodzenie netto  -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              X
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Wynagrodzenie netto
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            />

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              2 295,76
            </td>
          </tr>
        </tbody>

        <!-- XIII PODSTAWA SKŁADEK PŁACONYCH PRZEZ PRACODAWCĘ  -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              X
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              PODSTAWA SKŁADEK PŁACONYCH PRZEZ PRACODAWCĘ
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            />

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              3 200,00
            </td>
          </tr>
        </tbody>

        <!-- składka na ubezpieczenie emerytalne   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              1
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              składka na ubezpieczenie emerytalne
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >
              9,76%
            </td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              312,32
            </td>
          </tr>
        </tbody>

        <!-- składka na ubezpieczenie rentowe   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              2
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              składka na ubezpieczenie rentowe
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >
              4,50%
            </td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              144,00
            </td>
          </tr>
        </tbody>

        <!-- składka na ubezpieczenie wypadkowe   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              3
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              składka na ubezpieczenie wypadkowe
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >
              1,67%
            </td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              53,44
            </td>
          </tr>
        </tbody>

        <!-- Fundusz pracy   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              4
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Fundusz pracy
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >
              2,45%
            </td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              78,40
            </td>
          </tr>
        </tbody>

        <!-- Fundusz Gwarancyjnych Świadczeń Pracowniczych   -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              5
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              Fundusz Gwarancyjnych Świadczeń Pracowniczych
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            >
              0,10%
            </td>

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              3,20
            </td>
          </tr>
        </tbody>

        <!-- SKŁADKI ŁĄCZNIE (ZUS)  -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              1
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              SKŁADKI ŁĄCZNIE (ZUS)
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            />

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              1 278,60
            </td>
          </tr>
        </tbody>

        <!-- PODATEK DOCHODOWY (US)  -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              2
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              PODATEK DOCHODOWY (US)
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            />

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              217,00
            </td>
          </tr>
        </tbody>

        <!-- DLA PRACOWNIKA -wynagrodzenie netto  -->
        <tbody role="rowgroup">
          <tr
            id="__BVID__435__row_4989"
            role="row"
            data-pk="4989"
            class=""
          >
            <td
              aria-colindex="1"
              role="cell"
              class=""
            >
              3
            </td><td
              aria-colindex="2"
              role="cell"
              class=""
            >
              DLA PRACOWNIKA -wynagrodzenie netto
            </td><td
              aria-colindex="3"
              role="cell"
              class=""
            />

            <td
              aria-colindex="4"
              role="cell"
              class=""
            >
              2 295,76
            </td>
          </tr>
        </tbody>

      </table>
    </div>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BFormCheckbox, BTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BFormCheckbox,
    BTooltip,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      fields: [
        {
          key: 'Lp.',

        },
        {
          key: 'wyszczególnienie',

        },
        {
          key: 'stawka',
          name: 'dfs',
        },
        {
          key: 'wartość',

        },

      ],
      items: [
        {
          id: 4989,
          'Lp.': 'I',
          wyszczególnienie: 'Wynagrodzenie brutto',
          stawka: '',
          wartość: '3 200,00',

        },

      ],
    }
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
