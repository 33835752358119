import { render, staticRenderFns } from "./SinglePayroll.vue?vue&type=template&id=266bdbda&scoped=true&"
import script from "./SinglePayroll.vue?vue&type=script&lang=js&"
export * from "./SinglePayroll.vue?vue&type=script&lang=js&"
import style0 from "./SinglePayroll.vue?vue&type=style&index=0&id=266bdbda&lang=scss&scoped=true&"
import style1 from "./SinglePayroll.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "266bdbda",
  null
  
)

export default component.exports